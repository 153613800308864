import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import FindList from "../../assets/options.json";
import { OGDetailsObject } from '../interfaces/shared.model';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  api = "";
  paypalClientID = "";
  stripeHostName = "";
  stripeClientSecret = "";

  constructor(public http: HttpClient, private title: Title, private meta: Meta) {
    const API_PREPROD = 'https://preprodapi.shippn.com/api/';
    const API = 'https://api.shippn.com/api/';
    const APIV2 = 'https://v2.shippn.com/api/';
    const API_PERF = 'https://perfapi.shippn.com/api/';
    const PAYPAL_SANDBOX_CLIENT_ID = 'AXMIpteqo1mrdtRt61nGqtOnG1h-jCntAFf92cnU4NQKsXkV8eRIHdtwuyUdKZquYQszMf-6-x-y27VL';
    const PAYPAL_LIVE_CLIENT_ID = 'AbL-taAx6gn-D3ZXqlnNp65ehXkwAtZqxsqTLdgA1If9ZFkX1PEUvvwu1tHNU5eE2SqYAsnmb3ld2kM7';
    const STRIPE_LIVE_CLIENT_SECRET = "pk_live_r8tzVgjKdSZvFwagvXD8J1aN";
    const STRIPE_TEST_CLIENT_SECRET = "pk_test_F9f6GaPGqoxTgERoLTPHTYHE";

    // if (api == 'https://preprodapi.shippn.com/api/') {
    //   // Canlıya atarken de değişiklik gerekiyor.!!
    //   hostname = "https://preprodv2.shippn.com/";
    //   //hostname = "http://localhost:4200/";
    // } else {
    //   hostname = api;
    // }



    if (document.location.hostname === 'localhost' || document.location.hostname == '192.168.1.87' || document.location.hostname == '[::1]') {
      this.api = API_PREPROD;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
      this.stripeHostName = "http://localhost:4200/";
      this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
    } else if (document.location.hostname === 'preprod.shippn.com') {
      this.api = API_PREPROD;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
      this.stripeHostName = "https://preprodv2.shippn.com/";
      this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
    } else if (document.location.hostname === 'localhost:8181') {
      this.api = API_PREPROD;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
    } else if (document.location.hostname === 'preprodv2.shippn.com') {
      this.api = API_PREPROD;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
      this.stripeHostName = "https://preprodv2.shippn.com/";
      this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
    } else if (document.location.hostname === 'preprod-aws.shippn.com') {
      this.api = API_PREPROD;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
    } else if (document.location.hostname === 'shippn.com') {
      this.api = API;
      this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
      this.stripeHostName = "https://www.shippn.com/";
      this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
    } else if (document.location.hostname === 'www.shippn.com') {
      this.api = API;
      this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
      this.stripeHostName = "https://www.shippn.com/";
      this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
    } else if (document.location.hostname === 'v2.shippn.com') {
      this.api = APIV2;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
      this.stripeHostName = "https://www.shippn.com/";
      this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
    } else if (document.location.hostname === 'aws.shippn.com') {
      this.api = API;
      this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
      this.stripeHostName = "https://www.shippn.com/";
      this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
    } else if (document.location.hostname === 'perf.shippn.com') {
      this.api = API_PERF;
      this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
      this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
    } else {
      this.api = '';
      this.paypalClientID = '';
      this.stripeClientSecret = '';
    }
  }



  getUserProfileDataByUserID(userID, token) {
    return this.http.get(`${this.api}user/${userID}/profile`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }

  getUserByUserID(userID, token) {
    return this.http.get(`${this.api}users/${userID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }

  confirmationEmail(token) {
    return this.http.get(`${this.api}confirmation/validate/${token}`)
  }

  mailResetPassword(token, userData) {
    return this.http.post(`${this.api}forgotmypassword/${token}`,
      userData
    )
  }

  getUserCreditCards(token) {
    return this.http.get(`${this.api}user/creditcard`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }

  getFindList() {
    const findList = FindList
    return findList;
  }

  getTopRatedHostList() {
    return this.http.get(`${this.api}host/homepage`)
  }

  getHostCountries() {
    return this.http.get(`${this.api}host/countries`)
  }

  getHostCountryByCountryCode(countryCode) {
    return this.http.get(`${this.api}countries/${countryCode}`)
  }

  getHostMembersByCountryCode(countryCode) {
    return this.http.get(`${this.api}host/country/${countryCode}`)
  }

  getHostAddressByUserID(token, hostID) {
    return this.http.get(`${this.api}user/address/${hostID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getCurrencyList(token) {
    return this.http.get(`${this.api}currency`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateUserAddressByUserID(token, isDefaultAddress, cityName, countryCode, countryName, addressLine1, addressLine2, postCode, state) {
    return this.http.put(`${this.api}order/amount?name=`,
      {
        active: isDefaultAddress,
        city_name: cityName,
        country_code: countryCode,
        country_name: countryName,
        line1: addressLine1,
        line2: addressLine2,
        post_code: postCode,
        state: state
      },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  AddCreditCard(token, userData) {
    return this.http.post(`${this.api}payment/verification`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  verificationPaypal(token, userData) {
    return this.http.post(`${this.api}payment/verification`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  completeOrder(token, userData, orderID) {
    return this.http.post(`${this.api}order/${orderID}/complete`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  completeLabel(token, userData, orderID) {
    return this.http.post(`${this.api}order/${orderID}/label`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  completeLabelDynamic(token, userData, orderID) {
    return this.http.post(`${this.api}order/${orderID}/label-dynamic`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  completeLabelInterparcel(token, userData, orderID) {
    return this.http.post(`${this.api}interparcel/order/${orderID}/label`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  verifyCreditCardWithHasError(token, userData) {
    return this.http.post(`${this.api}stripe/customer/random`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  verifyCreditCardWithStatusOne(token, userData) {
    return this.http.post(`${this.api}stripe/customer/charge`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateOrderStatus(token, userData, orderID) {
    return this.http.put(`${this.api}order/${orderID}/status`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getAnonymousID(token, userData) {
    return this.http.post(`https://bfm-gce-api.shippn.com/anonymous`,
      userData
    )
  }

  getBFMShoppingCart(token, userData) {
    return this.http.post(`https://bfm-gce-api.shippn.com/shopping_carts`,
      userData
    )
  }

  updateOrderStatusToMailbox(token, userData, orderID) {
    return this.http.put(`${this.api}order/${orderID}/mailbox`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getHTS(token) {
    return this.http.get(`${this.api}hts/hts`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  changePassword(token, userData) {
    return this.http.post(`${this.api}password`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getCouponList(token) {
    return this.http.get(`${this.api}promotions/refer`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  deleteCreditCard(token, userData) {
    return this.http.put(`${this.api}user/creditcard`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  AddShopperAddress(token, userData) {
    return this.http.post(`${this.api}addresses`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }



  updateShopperStarReview(token, userData) {
    return this.http.post(`${this.api}vote`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateShopperTextReview(token, userData, orderID) {
    return this.http.post(`${this.api}review/${orderID}`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  sendHostRequest(token, userData) {
    if (token) {
      return this.http.post(`${this.api}host/country/request`,
        userData,
        {
          headers: new HttpHeaders({
            'x-access-token': token
          })
        }
      )
    }
    else if (!token) {
      return this.http.post(`${this.api}host/country/request`,
        userData,
        {
          headers: new HttpHeaders({
            'x-access-token': ""
          })
        }
      )
    }

  }

  getUserLoyality(userID, token) {
    return this.http.get(`${this.api}user/${userID}/loyalty`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateShopperAddress(token, userData, addressID) {
    return this.http.put(`${this.api}addresses/${addressID}`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getAddressByID(token, addressID) {
    return this.http.get(`${this.api}addresses/${addressID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateShipmentDetails(token, userData, orderID) {
    return this.http.put(`${this.api}order/${orderID}/shipment`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  deleteUserAccount(token, userData, userID) {
    return this.http.post(`${this.api}host/${userID}/unlist`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  sendMessage(token, userData, conversationID) {
    return this.http.post(`${this.api}${conversationID}/message`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getOrder(token, orderID) {
    return this.http.get(`${this.api}orders/${orderID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getBFMOrder(token, bfmOrderID) {
    return this.http.get(`${this.api}bfm/${bfmOrderID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getBFMOrderList(token) {
    return this.http.get(`${this.api}bfm/`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateOrder(token, userData, orderID, is_delete_val = 0) {
    return this.http.post(`${this.api}orders/update/${orderID}`,
      {
        is_delete: is_delete_val,
        ...userData
      },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  deleteOrderContent(token, userData, orderID) {
    return this.http.post(`${this.api}orders/update/${orderID}`,
      {
        action: { is_delete: 1 },
        ...userData
      },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getAddressOrder(token, userData) {
    return this.http.post(`${this.api}orders/`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateBFMOrder(token, userData, bfmOrderID) {
    return this.http.put(`${this.api}bfm/${bfmOrderID}/status`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  updateBFMOrderItemConfirmation(token, userData, bfmOrderID) {
    return this.http.put(`${this.api}bfm/${bfmOrderID}/confirmation`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  updateBFMOrderHostPaymentCalc(token, userData, bfmOrderID) {
    return this.http.put(`${this.api}bfm/host/${bfmOrderID}/cost`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getHostOrderListByStatus(token, userID, status) {
    return this.http.get(`${this.api}order/host/${userID}/status/${status}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getShopperOrderListByStatus(token, userID, status) {
    return this.http.get(`${this.api}order/shopper/${userID}/status/${status}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getMessages(token, conversationID) {
    return this.http.get(`${this.api}${conversationID}/message`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getHostReviews(token, userID) {
    return this.http.get(`${this.api}review/host/${userID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  uploadNationalID(token, userData) {
    return this.http.post(`${this.api}host/national`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  uploadPassport(token, userData) {
    return this.http.post(`${this.api}host/passport`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  sendVerifyEmail(token, userID) {
    return this.http.get(`${this.api}confirmation/resend/${userID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  sendReferMail(token, userData) {
    return this.http.post(`${this.api}promotions/refer/mail`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getBFMPurchasesDetails(bfmID) {
    return this.http.get(`${this.api}bfm/purchases/${bfmID}`,
    )
  }

  getBFMPuchases(token) {
    return this.http.get(`${this.api}bfm/purchases/`,
    )
  }

  sendBFMPurchasesProductsToBasket(bfmID, userData) {
    return this.http.post(`${this.api}bfm/purchases/${bfmID}/order`,
      null
    )
  }

  createReferCode(token, userData) {
    return this.http.post(`${this.api}promotions/refer`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  createDHLLabel(token, userData, orderID) {
    return this.http.post(`${this.api}order/${orderID}/label`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  confirmBFMOrder(token, userData) {
    return this.http.post(`${this.api}bfm/`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  confirmOrder(token, userData, orderID) {
    return this.http.post(`${this.api}order/${orderID}/pay`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }


  getBFMDataFromCartID(cartID, anonymousID) {
    return this.http.get(`https://bfm-gce-api.shippn.com/shopping_carts/${cartID}?anonymous_id=${anonymousID}`,
    )
  }

  getBFMDataSummaryFromCartID(cartID, anonymousID) {
    return this.http.get(`${this.api}bfm-basket/summary?anonymous_id=${anonymousID}&cart_id=${cartID}`,
    )
  }

  addBFMDataFromCartID(userData, cartID, anonymousID) {
    return this.http.put(`https://bfm-gce-api.shippn.com/shopping_carts/${cartID}?anonymous_id=${anonymousID}`,
      userData
    )
  }

  sendVerificationSMS(token, userData) {
    return this.http.post(`${this.api}sms/send_otp`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  verifySMSCode(token, userData) {
    return this.http.post(`${this.api}sms/verify_phone`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  deleteShopperAddress(token, userData) {
    return this.http.put(`${this.api}address/delete`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getOrderAmount(token, userData) {
    return this.http.put(`${this.api}order/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  getOrderAmountDynamic(token, userData) {
    return this.http.put(`${this.api}order/dynamic/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getOrderAmountInterParcel(token, userData) {
    return this.http.put(`${this.api}interparcel/order/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getHostMemberDetailsByID(memberID) {
    return this.http.get(`${this.api}host/${memberID}`)
  }

  getFAQData() {
    return this.http.get(`${this.api}faq`)
  }

  getHostMemberReviewsByID(memberID) {
    return this.http.get(`${this.api}review/host/${memberID}`)
  }

  getAllCountries() {
    return this.http.get(`${this.api}countries`)

  }

  getUserIPData() {
    return this.http.get(`https://ipapi.co/json/`)

  }

  signUpwithEmail(countryCode, email, mailPermission, name, password, surname, googleToken, secretKey) {
    return this.http.post(`${this.api}authentication/signup`, {
      country_code: countryCode,
      email: email,
      google: { google_token: googleToken, secret_key: secretKey },
      mail_permission: mailPermission,
      name: name,
      password: password,
      surname: surname
    })
  }

  signUpwithEmailv2(userData) {
    return this.http.post(`${this.api}authentication/signup`, userData)
  }

  becomeAHostwithEmail(countryCode, howDidYouHear, email, mailPermission, name, password, surname, googleToken, secretKey) {
    return this.http.post(`${this.api}authentication/becomehost`, {
      country_code: countryCode,
      find_us: howDidYouHear,
      is_etsy_user: false,
      email: email,
      google: { google_token: googleToken, secret_key: secretKey },
      mail_permission: mailPermission,
      name: name,
      password: password,
      surname: surname
    })
  }

  signUpwithFacebook(userData) {
    return this.http.post(`${this.api}authentication/facebook`,
      userData
    )
  }

  updateUserDataForFacebookSocialLogin(token, userData) {
    return this.http.post(`${this.api}authentication/facebook/email`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  signUpwithGoogle(userData) {
    return this.http.post(`${this.api}authentication/google`,
      userData
    )
  }

  signIn(email, password, reToken = '') {
    return this.http.post(`${this.api}authentication/signin`, {
      email: email,
      password: password,
      recaptcha_token: reToken
    })
  }

  calculatePricing(token, countryTo, countryFrom, weight, weightUnit, height, width, length, dimensionUnit, shopperID) {
    return this.http.put(`${this.api}order/amount?name=`,
      {
        country_to: countryTo,
        country_from: countryFrom,
        dimension: {
          weight: weight,
          weight_unit: weightUnit,
          height: height,
          width: width,
          length: length,
          dimension_unit: dimensionUnit
        },
        shopper_id: shopperID
      },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getOccupationList(token) {
    return this.http.get(`${this.api}occupations`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getLanguageList(token) {
    return this.http.get(`${this.api}language`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateUserBasicInfo(token, userData) {
    return this.http.post(`${this.api}user/account`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateUserPhoto(token, userData) {
    return this.http.post(`${this.api}user/profile`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }


  updateHostPaypalAcoount(token, userData) {
    return this.http.post(`${this.api}host/paypal`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  hostCreateOrder(token, userData, userID) {
    return this.http.post(`${this.api}host/${userID}/newpackage`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  sendSurvey(paramToken, userData) {
    return this.http.put(`${this.api}survey/${paramToken}`,
      userData
    )
  }

  forgotPassword(userData) {
    return this.http.put(`${this.api}forgotmypassword`,
      userData
    )
  }

  saveContactUsForm(token, userData) {
    if (token) {
      return this.http.post(`${this.api}contactus`,
        userData,
        {
          headers: new HttpHeaders({
            'x-access-token': token
          })
        }
      )
    }
    else if (!token) {
      return this.http.post(`${this.api}contactus`,
        userData
      )
    }

  }

  getHostOffDays(token) {
    return this.http.get(`${this.api}hostoff`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  removeHostOffDate(token, userData) {
    return this.http.post(`${this.api}hostoff/delete`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  addHostOffDate(token, userData) {
    return this.http.post(`${this.api}hostoff`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getContactUsMailData(token, ticketID) {
    if (token) {
      return this.http.get(`${this.api}contactus/${ticketID}`,
        {
          headers: new HttpHeaders({
            'x-access-token': token
          })
        }
      )
    }
    else if (!token) {
      return this.http.get(`${this.api}contactus/${ticketID}`,

      )
    }

  }

  updateUserSocialMedia(token, userData) {
    return this.http.post(`${this.api}user/social`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getInterestsList(token) {
    return this.http.get(`${this.api}interests`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  filterHostsByCountryCode(countryCode, destinationCountry, hasConsolidation, hasItemPhotos, hasRepacking, hasStorage) {
    return this.http.post(`${this.api}host/country/${countryCode}`, {
      destination: destinationCountry,
      service: {
        consolidation: hasConsolidation,
        item_pictures: hasItemPhotos,
        repacking: hasRepacking,
        storage: hasStorage
      }
    })
  }


  getWordpressData() {
    return this.http.get(`https://www.shippn.com/blog/wp-json/wp/v2/posts?per_page=3&_embed&categories=1&categories=1,81,7,213,277`, {
    })
  }

  getInstagramFeed() {
    return this.http.get(`https://instafeed-access-tokens.s3.amazonaws.com/shippn_token.json`, {
    })
  }

  updateWebsiteTitle(title) {
    this.title.setTitle(title);
  }

  updateWebsiteDescription(desc) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateWebsiteKeyword(keyword) {
    this.meta.updateTag({ name: 'keyword', content: keyword })
  }

  // Shipper Network

  postShipperNetwokr(payload) {
    return this.http.post(`${this.api}shipper/network`, payload)
  }

  // Host Funnel

  getServiceAgreement(token, userData) {
    return this.http.post(`${this.api}host/agreement/sign`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  deleteNationalIDImage(token, userData, userID) {
    return this.http.request('delete', `${this.api}user/${userID}/national`, {
      headers: new HttpHeaders({
        'x-access-token': token
      }), body: userData
    });
  }

  deletePassportImage(token, userData, userID) {
    return this.http.request('delete', `${this.api}user/${userID}/passport`, {
      headers: new HttpHeaders({
        'x-access-token': token
      }), body: userData
    });
  }

  mountStripeElements(token) {
    return this.http.post(`${this.api}bfm/`,
      { "payment_with_stripe3ds": 1 },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getBFMUrlDetails(BFMUrl) {
    return this.http.post('https://bfm-gce-api.shippn.com/preview_link', { url: BFMUrl }
    )
  }

  getOrderDetailFromOG(orderUrl) {
    return this.http.post<OGDetailsObject>('https://bfm-gce-api.shippn.com/preview_link', { url: orderUrl }
    )
  }

  // Update Order ==> Arrived ==> Mailbox, SHPN-5331 

  getOrderImage(token, orderID) {
    return this.http.get(`${this.api}order/${orderID}/images`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }
  updateOrderImage(token, orderID, image) {
    return this.http.post(`${this.api}order/${orderID}/images`,
      image,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }
  // In Host Dashboard Mailbox Unnecessary 
  deleteOrderImage(token, orderID, imagePath) {
    return this.http.delete(`${this.api}order/${orderID}/images/${imagePath}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }

  // SHPN-5288 Announcement Banner

  getAnnouncementBanner() {
    return this.http.get(`${this.api}banner`)
  }

  // SHPN-5429 BFM Extra Charge

  confirmBFMExtraCharge(token, userData) {
    return this.http.post(`${this.api}bfm/extra-charge-intent`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getBFMExtraCharge(bfmID) {
    return this.http.get(`${this.api}bfm/${bfmID}/extra-charge`)
  }

  getBFMExtraChargeIntent(payload, token) {
    return this.http.post(`${this.api}bfm/extra-charge-intent`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  // SHPN-5443
  getBFMCountries() {
    return this.http.get(`${this.api}bfm/countries`)
  }

  getAllMesssagesForUser(token) {
    return this.http.get(`${this.api}message-notifications`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      })
  }

  // /api/bfm/shopping-basket
  createShoppingBasketOnDBWithoutToken(basketData) {
    return this.http.post(`${this.api}bfm/shopping-basket`, basketData)
  }
  createShoppingBasketOnDB(basketData, token) {
    return this.http.post(`${this.api}bfm/shopping-basket`, basketData, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  getShipToPageData(countryName) {
    return this.http.get(`${this.api}landing-page/${countryName}`)
  }


  getHostStoreProductWithID(token, hostID) {
    return this.http.get(`${this.api}store-products?host_id=${hostID}`, {
      // headers: new HttpHeaders({
      //   'x-access-token': token
      // })
    })
  }

  getHostStoreProducts(token) {
    return this.http.get(`${this.api}store-products`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })

  }

  addHostStoreItem(token, payload) {
    return this.http.post(`${this.api}store-products`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  editHostStoreItem(token, payload) {
    return this.http.put(`${this.api}store-products`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }
  deleteHostStoreProduct(token, payload) {
    return this.http.delete(`${this.api}store-products`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        }),
        body: payload
      })
  }

  changeStatusOfHostProducts(token, payload) {
    return this.http.patch(`${this.api}store-products`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  getStockOfHostProduct(token, productID) {
    return this.http.get(`${this.api}store-products/stock?product_id=${productID}`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }


  changeStockOfHostProducts(token, payload) {

    return this.http.patch(`${this.api}store-products/stock`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })

  };


  // Shopper Consolidation


  createConsolidationOrder(token, mainOrderId, childOrdes) {
    return this.http.post(`${this.api}orders/shopper/consolidation/${mainOrderId}/create`, childOrdes, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }
  getConsolidationOrderDetail(token, mainOrderId, childOrdes) {
    return this.http.post(`${this.api}orders/shopper/consolidation/${mainOrderId}/create`, childOrdes, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  confirmConsolidationOrder(token, userData, orderID) {
    return this.http.post(`${this.api}consolidate/order/${orderID}/pay`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getConsolidationOrderAmount(token, userData) {
    return this.http.put(`${this.api}consolidate/order/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  getConsolidationOrderAmountDynamic(token, userData) {
    return this.http.put(`${this.api}consolidate/order/dynamic/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  getConsolidationOrderAmountInterparcel(token, userData) {
    return this.http.put(`${this.api}consolidate/interparcel/order/amount?name=`,
      userData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  updateConsolidationOrder(token, userData, orderID, is_delete_val = 0) {
    return this.http.post(`${this.api}consolidate/orders/update/${orderID}`,
      {
        is_delete: is_delete_val,
        ...userData
      },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getConsolidationOrderDetailWithMainOrder(token, mainOrderId) {
    return this.http.get(`${this.api}consolidate/orders/${mainOrderId}`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  hostCreateConsolidationOrder(token, parentOrderId, consolidationData) {
    return this.http.post(`${this.api}orders/host/consolidation/${parentOrderId}/create`,
      consolidationData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  hostEditConsolidationOrder(token, parentOrderId, consolidationData) {
    return this.http.put(`${this.api}orders/host/consolidation/${parentOrderId}/create`,
      consolidationData,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getHostConsolidationOrderDetail(token) {
    ///api/host/consolidate/orders
    return this.http.get(`${this.api}host/consolidate/orders`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  affiliateSignUp(payload) {
    return this.http.post(`${this.api}authentication/affiliate/signup`, payload)

  }

  affiliateSignupStep2(payload) {
    return this.http.put(`${this.api}authentication/affiliate/signup`, payload)
  }


  getAffiliateUsers() {
    return this.http.get(`${this.api}affiliate/users/`)

  }

  getAffiliateUser(id) {
    return this.http.get(`${this.api}affiliate/users/${id}`)
  }

  updateAffiliateUserStoreSettings(userID, token, payload) {
    return this.http.put(`${this.api}affiliate/users/${userID}`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }



  getAffiliateStoreOfAffiliate(affiliateID) {
    return this.http.get(`${this.api}store-products/affiliate/${affiliateID}`)
  }


  addProudctToAffiliateStore(payload, token) {
    return this.http.post(`${this.api}store-products/affiliate/`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })

  }
  editProductFromAffiliateStore(payload, token) {
    return this.http.put(`${this.api}store-products/affiliate/`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }

  publishProductFromAffiliateStore(payload, token) {
    return this.http.patch(`${this.api}store-products/affiliate/`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }


  deleteProductFromAffiliateStore(payload, token) {
    return this.http.delete(`${this.api}store-products/affiliate/`, {
      headers: new HttpHeaders({
        'x-access-token': token
      }),
      body: payload
    })

  }

  getStockOfAffiliateStoreProduct(token, productID, affiliateUserID) {
    return this.http.get(`${this.api}store-products/stock/affiliate/${affiliateUserID}?product_id=${productID}`, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })
  }


  changeStockOfAffiliateStoreProducts(token, payload) {

    return this.http.patch(`${this.api}store-products/stock`, payload, {
      headers: new HttpHeaders({
        'x-access-token': token
      })
    })

  };
  // /api/store-products/affiliate/<affilitae_user_id>

  getAffiliateStoreProductDetail(affiliateID, productId) {
    return this.http.get(`${this.api}store-products/affiliate/${affiliateID}?product_id=${productId}`)

  }
  getHostStoreProductDetail(hostID, productId) {
    return this.http.get(`${this.api}store-products?host_id=${hostID}&product_id=${productId}`)
  }

  getB2BAmount(token, payload) {
    return this.http.put(`${this.api}shipment/b2b/amount`,
      payload,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  confirmB2BOrder(token, payload) {
    return this.http.post(`${this.api}shipment/b2b/pay`,
      payload,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }
  listB2BShipmentsViaUserID(token, userID) {
    return this.http.get(`${this.api}shipment/b2b/user/shipments/${userID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getB2BShipmentDetailViaID(token, shipmentID) {
    return this.http.put(`${this.api}shipment/b2b/list/${shipmentID}`, {
      shipmentID: shipmentID
    },
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getB2BInvoiceViaID(token, shipmentID) {
    return this.http.get(`${this.api}shipment/b2b/invoice/shippn/${shipmentID}`,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getShopLandingPages(pageUrl) {
    return this.http.get(`${this.api}landing-page-shop/${pageUrl}`)
  }


  calculatePricingWithDiscount(token, payload) {
    return this.http.put(`${this.api}order/amount?name=`,
      payload,
      {
        headers: new HttpHeaders({
          'x-access-token': token
        })
      }
    )
  }

  getAllProhibitedItemsLists(){
    return this.http.get(`${this.api}prohibited/`)
  }

  getProhibitedItemsViaCountry(code) {
    return this.http.get(`${this.api}prohibited/${code}`)
  }

}