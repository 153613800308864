interface titleAndDescriptionModel {
    route: string, 
    description: string, 
    title: string

}

export const titlesAndDescriptions: titleAndDescriptionModel[] = [
    {
        route: "/shop-from-us-to-uk",
        description: "This page explains how to Shop online from the US through Shippn hosts that live in the US and get it delivered to your doorstep in UK.",
        title: "Shop from the US to UK  International Shipping with Shippn"
    },
    {
        route: "/shop-from-uk-to-au",
        description: "This page explains how to shop online from UK and ship through Shippn hosts that live in UK , get it delivered to your address in Australia.",
        title: "Shop from UK to Austrailia with Shippn - Fast and Easy Shipping"
    },
    {
        route: "/shop-from-us-to-il",
        description: "This page explains how to Shop online from the USA through Shippn hosts that live in USA. ",
        title: "Shop from US to Israil with Shippn - Fast and Reliable Shipping"
    },
    {
        route: "/shop-from-us-to-sg",
        description: "This page explains how to  Shop online from any  USA  online store through Shippn hosts that live in USA.",
        title: "Shop from USA to Singapore - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/shop-from-us-to-de",
        description: "This page explains how to  Shop online from any  USA  online store through Shippn hosts that live in USA.",
        title: "Shop from USA to Germany - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/shop-from-us-to-au",
        description: "This page explains how to  Shop online from the USA through Shippn hosts that live in USA and get your purchase delivered to your doorstep in Australia.",
        title: "Shop from USA to Australia - Easy and Reliable Shipping with Shippn"
    },
    {
        route: "/service-agreement",
        description: "This page indicates the the service agreement page for Shippn.",
        title: "Shippn Service Agreement - Understand Our Terms and Conditions"
    },
    {
        route: "/signin",
        description: "Welcome to our sign-in page! Sign in to access your account and start shopping, managing your profile, and more.",
        title: "Sign in to Shippn - Your Partner for Global Shipping Solutions"
    },
    {
        route: "/signup",
        description: "Ready to join our community? Sign up for an account today and enjoy exclusive access to our website's features.",
        title: "Sign Up for Shippn - Your Global Shipping Partner"
    },
    {
        route: "pricing",
        description: "Wondering about our pricing? Visit our pricing page to learn more about our packages and pricing plans.",
        title: "Shippn Pricing - Affordable and Transparent Global Shipping Rates"
    },
    {
        route: "shipping-cost-calculator",
        description: "Trying to calculate your shipping costs? Our shipping cost calculation page makes it easy to estimate the shipping fees for your order. ",
        title: "Calculate Your Shipping Cost with Shippn - Fast and Easy"
    },
    {
        route: "howitworks-for-shoppers",
        description: "Visit our 'How It Works' page for shoppers and discover how our platform makes it easy to shop online from anywhere in the world. ",
        title: "Learn How Shippn Works for Shoppers - Simplify Your Shipping Process"
    },
    {
        route: "getaddress-host",
        description: "This page explains how to get a host address from Shippn.com.",
        title: "Get address and Be a Member I Shippn "
    },
    {
        route: "shop-from/",
        description: "This page is about shopping online from the USA, Canada, and Europe and have your purchases shipped to Australia with Shippn. ",
        title: "Shop from USA and UK to Australia with Shippn - Fast Shipping"
    },
    {
        route: "bfm",
        description: "This page explains how Shippn's 'Buy For Me' service is the perfect solution for shoppers who want to shop globally.",
        title: "Buy For Me Service - Let Shippn Shop for You Worldwide"
    },
    {
        route: "bfm/explanation",
        description: "This page explains how our 'Buy For Me' service works for global shoppers.",
        title: "Buy For Me Service Explained - Shippn Package Forwarding"
    },
    {
        route: "howitworks-for-hosts",
        description: "This page has all the information about how it works for hosts.",
        title: "Learn How Shippn Works for Hosts - Simplify Your Shipping Process"
    },
    {
        route: "terms",
        description: "This page covers important topics such as liability, intellectual property, and privacy, as well as the terms and conditions of using our various services. ",
        title: "Shippn Terms and Conditions - Read Our Policies and Guidelines"
    },
    {
        route: "faq",
        description: " Our 'Frequently Asked Questions' page has all the answers. ",
        title: "Shippn FAQ - Your Questions Answered "
    },
    {
        route: "trust-safety",
        description: "at Shippn our 'Trust & Safety' page outlines the measures we take to ensure that your shopping and shipping experience with us is as safe and secure as possible. ",
        title: "Trust and Safety - Shippn's Commitment to Secure Global Shipping"
    },
    {
        route: "privacy-policy",
        description: " Shippn's 'Privacy Policy' page outlines the ways we collect, use, and protect your personal information.",
        title: "Shippn Privacy Policy - Your Data is Safe with Us"
    },
    {
        route: "/become-a-host",
        description: "Shippn 'Become a Host' page provides all the information you need to get started.",
        title: "Join Shippn's Host Program - Earn Money with Your Shipping Address"
    },
    {
        route: "/help",
        description: "At Shippn, we're here to help you shop and ship internationally with ease. ",
        title: "Shippn Help Center - Find Answers to Your Global Shipping Questions"
    },
    {
        route: "/responsible-shipping",
        description: "At Shippn, our Responsible Shipping page outlines our policies and procedures for protecting your shipments.",
        title: "Responsible Shipping with Shippn - Our Commitment to Sustainability"
    },
    {
        route: "/shipping-standards",
        description: "Shippn sets high shipping standards to ensure your packages are delivered safely and on time. ",
        title: "Shippn Shipping Standards - Our Commitment to Quality and Service"
    },
    {
        route: "/prohibited-items",
        description: "Stay compliant with international shipping laws by checking the list of prohibited items before shipping. ",
        title: "Prohibited Items - List of Restricted Products for Global Shipping"
    },
    {
        route: "/contact-us",
        description: "Contact Shippn team and get answers to  any questions you may have about our services, pricing, or shipping regulations.",
        title: "Contact Shippn - We're Here to Help with Your Global Shipping Needs"
    },
    {
        route: "/about",
        description: "Learn about Shippn's mission, values, and how we make international shopping easier and more affordable for everyone.",
        title: "About Shippn - Your Trusted Partner for Global Shipping Solutions"
    },
    {
        route: "/shipper-network",
        description: "Shippn's shipper network is composed of reliable and trusted carriers that deliver packages worldwide. ",
        title: "Shipper Network-Reliable Trustes Carriers"
    },
    {
        route: "/HELLO10",
        description: "Enjoy 10% off your first shipment with Shippn using promo code HELLO10. ",
        title: "Use code HELLO10 for %10 discount"
    },
    {
        route: "/hello10",
        description: "Enjoy 10% off your first shipment with Shippn using promo code HELLO10. ",
        title: "Use code HELLO10 for %10 discount"
    },
    {
        route: "/home",
        description: "Shop online from more than 25 countries and get it delivered to your doorstep! Sign up for free and start shopping from anywhere in the world with Shippn. ",
        title:"Worldwide Shipping Service | Shop Anywhere, Ship Everywhere - Shippn"
    },
    {
        route: "/b2b",
        description: "Simplify your international shipping process with Shippn. Ship your products globally, connect your business with your customers worldwide.",
        title:"Worldwide Shipping Service | Shop Anywhere, Ship Everywhere - Shippn"
    }


]