import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ShoppingService {
    api = "";
    paypalClientID = "";
    stripeHostName = "";
    stripeClientSecret = "";

    constructor(
        private http: HttpClient) {
        const API_PREPROD = 'https://preprodapi.shippn.com/api/';
        const API = 'https://api.shippn.com/api/';
        const APIV2 = 'https://v2.shippn.com/api/';
        const API_PERF = 'https://perfapi.shippn.com/api/';
        const PAYPAL_SANDBOX_CLIENT_ID = 'AXMIpteqo1mrdtRt61nGqtOnG1h-jCntAFf92cnU4NQKsXkV8eRIHdtwuyUdKZquYQszMf-6-x-y27VL';
        const PAYPAL_LIVE_CLIENT_ID = 'AbL-taAx6gn-D3ZXqlnNp65ehXkwAtZqxsqTLdgA1If9ZFkX1PEUvvwu1tHNU5eE2SqYAsnmb3ld2kM7';
        const STRIPE_LIVE_CLIENT_SECRET = "pk_live_r8tzVgjKdSZvFwagvXD8J1aN";
        const STRIPE_TEST_CLIENT_SECRET = "pk_test_F9f6GaPGqoxTgERoLTPHTYHE";

        // if (api == 'https://preprodapi.shippn.com/api/') {
        //   // Canlıya atarken de değişiklik gerekiyor.!!
        //   hostname = "https://preprodv2.shippn.com/";
        //   //hostname = "http://localhost:4200/";
        // } else {
        //   hostname = api;
        // }


        if (document.location.hostname === 'localhost' || document.location.hostname == '192.168.1.87') {
            this.api = API_PREPROD;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
            this.stripeHostName = "http://localhost:4200/";
            this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
        } else if (document.location.hostname === 'preprod.shippn.com') {
            this.api = API_PREPROD;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
            this.stripeHostName = "https://preprodv2.shippn.com/";
            this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
        } else if (document.location.hostname === 'localhost:8181') {
            this.api = API_PREPROD;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
        } else if (document.location.hostname === 'preprodv2.shippn.com') {
            this.api = API_PREPROD;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
            this.stripeHostName = "https://preprodv2.shippn.com/";
            this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
        } else if (document.location.hostname === 'preprod-aws.shippn.com') {
            this.api = API_PREPROD;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
        } else if (document.location.hostname === 'shippn.com') {
            this.api = API;
            this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
            this.stripeHostName = "https://www.shippn.com/";
            this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
        } else if (document.location.hostname === 'www.shippn.com') {
            this.api = API;
            this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
            this.stripeHostName = "https://www.shippn.com/";
            this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
        } else if (document.location.hostname === 'v2.shippn.com') {
            this.api = APIV2;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
            this.stripeHostName = "https://www.shippn.com/";
            this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
        } else if (document.location.hostname === 'aws.shippn.com') {
            this.api = API;
            this.paypalClientID = PAYPAL_LIVE_CLIENT_ID;
            this.stripeHostName = "https://www.shippn.com/";
            this.stripeClientSecret = STRIPE_LIVE_CLIENT_SECRET;
        } else if (document.location.hostname === 'perf.shippn.com') {
            this.api = API_PERF;
            this.paypalClientID = PAYPAL_SANDBOX_CLIENT_ID;
            this.stripeClientSecret = STRIPE_TEST_CLIENT_SECRET;
        } else {
            this.api = '';
            this.paypalClientID = '';
            this.stripeClientSecret = '';
        }
    }

    translateSearchText(value: string): Observable<any> {
        return this.http.get<any>('https://translate.googleapis.com/translate_a/single?client=gtx&sl=tr&tl=en&dt=t&q=' + value);
    }

    translateDescription(value: string): Observable<any> {
        return this.http.get<any>('https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=tr&dt=t&q=' + value);
    }

    getProducts(value: string): Observable<any> {
        return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/products?pid=uid2500-1150750-18&format=json&' + value);
    }

    getSingleProduct(value: string): Observable<any> {
        return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/products/' + value + '?pid=uid2500-1150750-18&format=json&');
    }

    getHighlightedProducts(): Observable<any> {
        return this.http.get<any>(this.api + 'getproducts?parameters=products?pid=uid2500-1150750-18%26format=json%26limit=12%26offset=0');
    }

    setfavouriteProduct(param: any): Observable<any> {
        return this.http.post<any>(this.api + 'favourite/add', param);
    }

    getfavouriteList(): Observable<any> {
        return this.http.get<any>(this.api + 'favourite/list');
    }

    getBrandDetail(brandName: string): Observable<any> {
        return this.http.get<any>(this.api + 'brands/' + brandName)
    }

    checkStock(productId: number): Observable<any> {
        return this.http.get<any>(this.api + 'products/checkstock/' + productId)
    }
    getBrands(): Observable<any> {
        return this.http.get<any>('https://zinc.amerikadaniste.com/api/v2/brands?pid=uid2500-1150750-18');
    }
    searchShopping(term){
        return this.http.get<any>(`https://zinc.amerikadaniste.com/api/v2/site/autocomplete/omnibar/v2?locale=en-US&pid=shopstyle&term=${term}`)
    }
}
