import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { PerfumeAteilerStoreComponent } from './components/perfumeatelier/perfumeatelier.component';
import { Hi10Component } from './components/hi10/hi10';

const routes: Routes = [

  {
    path: 'shop/:pageURL', redirectTo: 'en/shop/:pageURL'
  },
  {
    path: 'shop-from', redirectTo: 'en/shop-from'
  },
  {
    path: 'Ship-to-United-States', loadChildren: () => import('./modules/booster/booster.module').then(m => m.BoosterModule)
  },
  {
    path: 'ship-to-united-states', loadChildren: () => import('./modules/booster/booster.module').then(m => m.BoosterModule)
  },
  {
    path: ':lang/ship-to-united-states', loadChildren: () => import('./modules/booster/booster.module').then(m => m.BoosterModule)
  },
  {
    path: 'ship-to/:country', redirectTo: '/en/ship-to/:country'
  },
  {
    path: 'signin', redirectTo: '/en/signin'
  },
  {
    path: 'signup', redirectTo: '/en/signup'
  },
  {
    path: 'about', redirectTo: '/en/about'
  },
  {
    path: 'bfm/:bfmID/extra-charge/', redirectTo: 'en/bfm/:bfmID/extra-charge/'
  },
  {
    path: 'bfm', redirectTo: '/en/bfm'
  },
  {
    path: 'bfm/explanation', redirectTo: '/en/bfm/explanation'
  },
  {
    path: 'shipping-cost-calculator', redirectTo: '/en/shipping-cost-calculator'
  },
  {
    path: 'signup/:landingPage', redirectTo: '/en/signup/:landingPage'
  },
  {
    path: 'member', redirectTo: '/en/member'
  },
  {
    path: 'settings', redirectTo: '/en/settings/profile'
  },
  {
    path: 'settings/:param', redirectTo: '/en/settings/:param'
  },
  {
    path: 'campaigns', redirectTo: '/en/campaigns'
  },
  {
    path: 'pricing', redirectTo: '/en/shipping-cost-calculator'
  },
  {
    path: 'howitworks-for-shoppers', redirectTo: '/en/howitworks-for-shoppers'
  },
  {
    path: 'howitworks-for-hosts', redirectTo: '/en/howitworks-for-hosts'
  },
  {
    path: 'getaddress-host', redirectTo: '/en/getaddress-host'
  },
  {
    path: 'host', redirectTo: '/en/host'
  },
  {
    path: 'shorkstore', redirectTo: '/en/shorkstore'
  },
  {
    path: 'perfumeatelier', redirectTo: 'en/perfumeatelier'
  },

  {
    path: 'trust-safety', redirectTo: '/en/trust-safety'
  },
  {
    path: 'shopper-details', redirectTo: '/en/shopper-details'
  },
  {
    path: 'terms', redirectTo: '/en/terms'
  },
  {
    path: 'faq', redirectTo: '/en/faq'
  },
  {
    path: 'affiliate', redirectTo: '/en/affiliate'
  },
  {
    path: 'privacy-policy', redirectTo: '/en/privacy-policy'
  },
  {
    path: 'become-a-host', redirectTo: '/en/become-a-host'
  },
  {
    path: 'become-an-affiliate', redirectTo: '/en/become-an-affiliate'
  },
  {
    path: 'b2b', redirectTo: '/en/b2b'
  },
  {
    path: 'host-funnel', redirectTo: '/en/host-funnel'
  },
  {
    path: 'verification', redirectTo: '/en/verification'
  },
  {
    path: 'dashboard', redirectTo: '/en/dashboard'
  },
  {
    path: 'surveys', redirectTo: '/en/surveys'
  },
  {
    path: "responsible-shipping", redirectTo: '/en/responsible-shipping'
  },
  {
    path: "shipping-standards", redirectTo: '/en/shipping-standards'
  },
  {
    path: "help", redirectTo: '/en/help'
  },
  {
    path: "prohibited-items", redirectTo: '/en/prohibited-items'
  },
  {
    path: "about", redirectTo: '/en/about'
  },
  {
    path: "contact-us", redirectTo: '/en/contact-us'
  },
  {
    path: "email-confirmation/:token", redirectTo: '/en/email-confirmation/:token'
  },
  {
    path: "forgotmypassword/:token", redirectTo: '/en/forgotmypassword/:token'
  },
  {
    path: "shipper-network", redirectTo: '/en/shipper-network'
  },
  {
    path: "shop-from-us-to-uk", redirectTo: '/en/route/shop-from-us-to-uk'
  },
  {
    path: "shop-from-us-to-il", redirectTo: '/en/route/shop-from-us-to-il'
  },
  {
    path: "shop-from-us-to-sg", redirectTo: '/en/route/shop-from-us-to-sg'
  },
  {
    path: "shop-from-us-to-de", redirectTo: '/en/route/shop-from-us-to-de'
  },
  {
    path: "shop-from-us-to-au", redirectTo: '/en/route/shop-from-us-to-au'
  },
  {
    path: "route", redirectTo: '/en/route'
  },
  {
    path: "service-agreement", redirectTo: '/en/service-agreement'
  },
  {
    path: 'HELLO10', redirectTo: '/en/hello10'
  },
  {
    path: 'hello10', redirectTo: '/en/hello10'
  },
  {
    path: 'HI10', redirectTo: '/en/hi10'
  },
  {
    path: 'hi10', redirectTo: '/en/hi10'
  },
  {
    path: 'shopping', redirectTo: '/en/shopping'
  },
  {
    path: '', redirectTo: '/en/home', pathMatch: 'full'
  },
  {
    path: ':lang', redirectTo: '/en/home', pathMatch: 'full'
  },
  {
    path: ':lang/', redirectTo: '/en/home', pathMatch: 'full'
  },
  {
    path: ':lang/home', component: HomepageComponent
  },
  {
    path: ':lang/signin', loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: ':lang/signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: ':lang/signup/:landingPage', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: ':lang/member', loadChildren: () => import('./components/dashboard/order/order.module').then(m => m.OrderModule)
  },
  {
    path: ':lang/settings', loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: ':lang/bfm', loadChildren: () => import('./modules/bfm/bfm.module').then(m => m.BfmModule)
  },
  {
    path: ':lang/campaigns', loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule)
  },
  {
    path: ':lang/ship-to/:country', loadChildren: () => import('./modules/ship-to/ship-to.module').then(m => m.ShipToModule)
  },
  {
    path: ':lang/pricing', loadChildren: () => import('./modules/shipping-price-calculation/shipping-price-calculation.module').then(m => m.ShippingPriceCalculationModule)
  },
  {
    path: ':lang/shipping-cost-calculator', loadChildren: () => import('./modules/shipping-price-calculation/shipping-price-calculation.module').then(m => m.ShippingPriceCalculationModule)
  },
  {
    path: ':lang/howitworks-for-shoppers', loadChildren: () => import('./modules/howitworksforshoppers/howitworksforshoppers.module').then(m => m.HowitworksforshoppersModule)
  },
  {
    path: ':lang/howitworks-for-hosts', loadChildren: () => import('./modules/howitworksforhosts/howitworksforhosts.module').then(m => m.HowitworksforhostsModule)
  },
  {
    path: ':lang/shop-from', loadChildren: () => import('./modules/hosts/hosts.module').then(m => m.HostsModule)
  },
  {
    path: ':lang/getaddress-host', loadChildren: () => import('./modules/host-detail/host-detail.module').then(m => m.HostDetailModule)
  },
  {
    path: ':lang/host', loadChildren: () => import('./modules/host/host.module').then(m => m.HostModule)
  },
  {
    path: ':lang/shorkstore', loadChildren: () => import('./modules/influencer-store/influencer-store.module').then(m => m.InfluencerStoreModule)
  },
  {
    path: ':lang/affiliate', loadChildren: () => import('./modules/affiliate-store/affiliate-store.module').then(m => m.AffiliateStoreModule)
  },
  {
    path: ':lang/perfumeatelier', component: PerfumeAteilerStoreComponent
  },
  {
    path: ':lang/hi10', component: Hi10Component
  },
  {
    path: ':lang/trust-safety', loadChildren: () => import('./modules/trust-safety/trust-safety.module').then(m => m.TrustSafetyModule)
  },
  {
    path: ':lang/shopper-details', loadChildren: () => import('./modules/shopper-details/shopper-details.module').then(m => m.ShopperDetailsModule)
  },
  {
    path: ':lang/terms', loadChildren: () => import('./modules/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: ':lang/faq', loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
  },

  {
    path: ':lang/privacy-policy', loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },

  {
    path: ':lang/become-a-host', loadChildren: () => import('./modules/become-a-host/become-a-host.module').then(m => m.BecomeAHostModule)
  },
  {
    path: ':lang/become-an-affiliate', loadChildren: () => import('./modules/become-an-affiliate/become-an-affiliate.module').then(m => m.BecomeAnAffiliateModule)
  },
  {
    path: ':lang/b2b', loadChildren: () => import('./modules/become-a-b2b-user/become-a-b2b-user.module').then(m => m.BecomeAB2BUserModule)
  },
  {
    path: ':lang/host-funnel', loadChildren: () => import('./modules/host-funnel/host-funnel.module').then(m => m.HostFunnelModule)
  },
  {
    path: ':lang/verification', loadChildren: () => import('./modules/verification/verification.module').then(m => m.VerificationModule)
  },
  {
    path: ':lang/dashboard', loadChildren: () => import('./modules/order-dashboard/order-dashboard.module').then(m => m.OrderDashboardModule)
  },
  {
    path: ':lang/surveys', loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: ":lang/responsible-shipping", loadChildren: () => import('./modules/responsible-shipping/responsible-shipping.module').then(m => m.ResponsibleShippingModule)
  },
  {
    path: ":lang/shipping-standards", loadChildren: () => import('./modules/shipping-standards/shipping-standards.module').then(m => m.ShippingStandardsModule)
  },
  {
    path: ":lang/help", loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: ":lang/prohibited-items", loadChildren: () => import('./modules/prohibited-items/prohibited-items.module').then(m => m.ProhibitedItemsModule)
  },
  {
    path: ":lang/about", loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule)
  },
  {
    path: ":lang/contact-us", loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: ":lang/email-confirmation/:token", loadChildren: () => import('./modules/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationModule)
  },
  {
    path: ":lang/forgotmypassword/:token", loadChildren: () => import('./modules/forgot-password-email/forgot-password-email.module').then(m => m.ForgotPasswordEmailModule)
  },
  {
    path: ":lang/shipper-network", loadChildren: () => import('./modules/shipper-network/shipper-network.module').then(m => m.ShipperNetworkModule)
  },
  {
    path: ":lang/shop-from-us-to-uk", loadChildren: () => import('./modules/ustouk-static/ustouk-static.module').then(m => m.UStoUKStaticModule)
  },
  {
    path: ":lang/route", loadChildren: () => import('./modules/marketing-module/marketing-module.module').then(m => m.MarketingModuleModule)
  },
  {
    path: ":lang/service-agreement", loadChildren: () => import('./modules/service-agreement/service-agreement.module').then(m => m.ServiceAgreementModule)
  },
  {
    path: ':lang/HELLO10', loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: ':lang/hello10', loadChildren: () => import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: ':lang/shopping', loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
  },
  {
    path: ':lang/shop/:pageURL', loadChildren:() => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
  },
  {
    path: "**", pathMatch: "full", component: NotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    scrollPositionRestoration: 'disabled',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
