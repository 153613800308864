<div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div style="max-width: 520px;" class="modal-dialog modal-dialog-right">
        <div style="height: 100%;" class="modal-content">
            <div style="background-color: #e5e5e5;display: inline-flex;align-items: center;" class="modal-header">
                <div style="display: inline-flex;align-items: center;">
                    <button style="padding-left: 0px;padding-right: 0px;display: contents;" type="button" class="close"
                        data-dismiss="modal" aria-label="Close">
                        <span style="color: black;margin-right: 5px;" aria-hidden="true">&times;</span>
                    </button>
                    <h5 style="font-weight: 900;margin-right: 10px;" class="modal-title" id="exampleModalLabel">
                        Notification Center
                    </h5>
                </div>
                <!-- <div style="display: inline-flex;" *ngIf="!isShopper">
                    <div class="select--wrapper"
                        style="margin-bottom: 0px !important;background-color: white !important; border-radius:5px;">
                        <i class="fas fa-chevron-down"
                            style="position: absolute; right: 15px; top: 17px;float: right; color: #222222; font-size: 12px;"
                            aria-hidden="true"></i>
                        <select [(ngModel)]="messageAreaType"
                            style="margin-bottom: 0px !important; width: 280px !important; border-radius: 5px;padding:12px 15px !important;border: none;height: 43px;"
                            class="form-select mb-3 shippn-input form-control-sm" aria-label=".form-select-lg example">
                            <option value="0">All</option>
                            <option value="1">{{messageAreaTypeText}}</option>
                            <option value="2">Messages with Shippn Support</option>
                        </select>
                    </div>
                </div> -->
            </div>
            <div #scrollMe style="overflow-y: auto;height: 585px;" class="modal-body">
                <div style="padding: 0;" class="container-fluid">
                    <div class="row">
                        <div *ngIf="messageData?.length == 0" class="col-md-12">
                            <p
                                style="color: black;font-size: 18px;font-weight: bold;text-align: center;margin: 0px;margin-bottom: 0px;margin-top: 10px;">
                                No unread messages.
                            </p>
                        </div>
                        <div *ngFor="let message of messageData;let i = index;" class="col-md-12 notification-item"
                            (click)="goToOrderDetail(message)" data-dismiss="modal"
                            aria-label="Close">
                            <div appAmIVisible (elementVisible)="getNotificationsData($event)"
                                [isTargetElement]="(i + 1) == messageData.length">
                                <div>
                                    <div *ngIf="userData?.type == 1">
                                        <div class="notification-message-box">
                                            <div class="notification-message"
                                                [ngClass]="{'seen-notification':message.message.seen_by_shopper}">
                                                <div class="notification-message-header">
                                                    <p>
                                                        {{message.order_number}} - <span>{{message.msg_obj.user_name}}
                                                            {{message.msg_obj.user_surname}}</span> <span
                                                            *ngIf="message.msg_obj.user_name.length < 3">Shippn Support</span>
                                                    </p>
                                                    <p>
                                                        {{message.msg_obj.message_date * 1000 | date: 'mediumDate'}}
                                                    </p>
                                                </div>
                                                <div class="notification-message-body">
                                                    <p style="color: #000;font-size: 15px;font-weight: 300;margin: 0px;"
                                                        *ngIf="message.msg_obj?.text">
                                                        {{message.msg_obj.text | slice:0:60}}<span
                                                            *ngIf="message.msg_obj?.text?.length > 60">...</span>
                                                    </p>
                                                    <div *ngIf="message?.msg_obj.text?.length == 0">
                                                        <img style="width: 20px;"
                                                            src="../../../../../assets/img/shippn-attachment-icon.png"
                                                            alt="Shippn message attachment icon">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="notification-message-unseen-count-wrapper"
                                                *ngIf="message.message.seen_by_shopper == 0">
                                                <span class="notification-message-unseen-count">
                                                    {{message?.msg_obj?.count}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="userData?.type == 2">
                                        <div class="notification-message-box">
                                            <div class="notification-message"
                                                [ngClass]="{'seen-notification':message.message.seen_by_host}">
                                                <div class="notification-message-header">
                                                    <p>
                                                        {{message.order_number}} - <span>{{message.msg_obj.user_name}}
                                                            {{message.msg_obj.user_surname}}</span> <span
                                                            *ngIf="message.msg_obj.user_name.length < 3">Shippn Support</span>
                                                    </p>
                                                    <p>
                                                        {{message.msg_obj.message_date * 1000 | date: 'mediumDate'}}
                                                    </p>
                                                </div>
                                                <div class="notification-message-body">
                                                    <p style="color: #000;font-size: 15px;font-weight: 300;margin: 0px;"
                                                        *ngIf="message.msg_obj?.text">
                                                        {{message.msg_obj.text | slice:0:60}}<span
                                                            *ngIf="message.msg_obj?.text?.length > 60">...</span>
                                                    </p>
                                                    <div *ngIf="message.msg_obj?.text?.length == 0">
                                                        <img style="width: 20px;"
                                                            src="../../../../../assets/img/shippn-attachment-icon.png"
                                                            alt="Shippn message attachment icon">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="notification-message-unseen-count-wrapper"
                                                *ngIf="message.message.seen_by_host == 0">
                                                <span class="notification-message-unseen-count">
                                                    {{message.msg_obj.count}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>